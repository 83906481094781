<template>
  <div v-if="loading" class="loading-container d-flex justify-center align-center">
    <div class="loading">
      <v-progress-circular indeterminate size="100" color="secondary"> </v-progress-circular>
    </div>
  </div>
</template>
<style scoped>
.loading-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.5;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
</style>
<script>
export default {
  name: 'Loading',
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
